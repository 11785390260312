import { CoolerTypes } from '../../../../helpers/types';

// eslint-disable-next-line import/prefer-default-export
export const productFields = [
    {
        label: 'Monitor', source: 'specs.monitor.products', filter: { type: 'monitor' }, descriptionLabel: 'monitor', productType: 'monitor',
    },
    {
        label: 'Mouse', source: 'specs.mouse.products', filter: { type: 'mouse' }, descriptionLabel: 'mouse', productType: 'mouse',
    },
    {
        label: 'Teclado', source: 'specs.keyboard.products', filter: { type: 'keyboard' }, descriptionLabel: 'teclado', productType: 'keyboard',
    },
    {
        label: 'Mother', source: 'specs.mother.products', filter: { type: 'mother' }, descriptionLabel: 'mother', productType: 'mother',
    },
    {
        label: 'CPU', source: 'specs.cpu.products', filter: { type: 'cpu' }, descriptionLabel: 'CPU', productType: 'cpu',
    },
    {
        label: 'CPU Cooler', source: 'specs.cpuCooler.products', filter: { type: 'cpu_cooler' }, descriptionLabel: 'CPU Cooler', productType: 'cpuCooler',
    },
    {
        label: 'RAM', source: 'specs.ram.products', filter: { type: 'ram' }, descriptionLabel: 'RAM', productType: 'ram',
    },
    {
        label: 'GPU', source: 'specs.gpu.products', filter: { type: 'gpu' }, descriptionLabel: 'GPU', productType: 'gpu',
    },
    {
        label: 'Almacenamiento', source: 'specs.storage.products', filter: { type: 'storage' }, descriptionLabel: 'almacenamiento', productType: 'storage',
    },
    {
        label: 'Gabinete', source: 'specs.case.products', filter: { type: 'case' }, descriptionLabel: 'gabinete', productType: 'case',
    },
    {
        label: 'Fuente', source: 'specs.power.products', filter: { type: 'power' }, descriptionLabel: 'fuente', productType: 'power',
    },
    {
        label: 'Fans', source: 'specs.fans.products', filter: { type: 'case_fan' }, descriptionLabel: 'fans', productType: 'fans',
    },
];
