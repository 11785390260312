import React from 'react';
import {
    ReferenceArrayInput,
    AutocompleteArrayInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const ProductBuildItem = ({
    product: {
        label, source, filter, descriptionLabel, productType,
    },
    handleSelect,
    handleSelectionChange,
}) => (
    <>
        <ReferenceArrayInput
            label={label}
            reference="products"
            allowDuplicates
            source={source}
            filter={filter}
            // IMPORTANT: we use perPage because react-admin by default only fetches 25 items
            perPage={999}
            onChange={
                (newSelectedItems) => handleSelectionChange(newSelectedItems, productType)
            }
        >
            <AutocompleteArrayInput
                optionText="niceAdminName"
                onSelect={
                    ({ id, price, pricing }) => handleSelect(id, price, pricing, productType)
                }
            />
        </ReferenceArrayInput>
        <RichTextInput source={`specs.${productType}.description`} label={`Descripción ${descriptionLabel}. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente`} />
    </>
);
export default ProductBuildItem;
