const CartProductTypes = {
    BUILD: 1,
    PRODUCT: 2,
    PRO_BUILDER: 3,
};

const ProductTypes = {
    MOTHER: 'mother',
    CPU: 'cpu',
    CPU_COOLER: 'cpu_cooler',
    RAM: 'ram',
    GPU: 'gpu',
    STORAGE: 'storage',
    CASE: 'case',
    CASE_FAN: 'case_fan',
    POWER: 'power',
    MONITOR: 'monitor',
    MOUSE: 'mouse',
    KEYBOARD: 'keyboard',
    HEADSET: 'headset',
    MICROPHONE: 'microphone',
    CHAIR: 'chair',
    ACCESSORIES: 'accessories',
    SPEAKERS: 'speakers',
    FURNITURE: 'furniture',
    THERMAL_PASTE: 'thermal_paste',
    OPERATIVE_SYSTEM: 'operative_system',
    OTHER: 'other',
    BUILD: 'build',
    NOTEBOOKS: 'notebook',
};

const CpuBrands = {
    INTEL: 'intel',
    AMD: 'amd',
};

const RamStickTypes = {
    DDR4: 'ddr4',
    DDR5: 'ddr5',
};

const CaseFanSlotWidths = {
    W_120: 120,
    W_140: 140,
    W_240: 240,
    W_280: 280,
    W_360: 360,
    W_420: 420,
};

const MotherFormFactors = {
    EATX: 'E-ATX',
    ATX: 'ATX',
    MATX: 'M-ATX',
    ITX: 'ITX',
};

const FormFactors = {
    FULL_TOWER: 'Full-Tower',
    MID_TOWER: 'Mid-Tower',
    MINI_TOWER: 'Mini-Tower',
};
const CoolerTypes = {
    WATER_COOLER: 'WaterCooler',
    AIR_COOLER: 'AirCooler',
};
const StorageConnections = {
    SATA: 'SATA',
    M2: 'M2',
    USB: 'USB',
};
const StorageProtocols = {
    SATA3: 'SATA3',
    NVME: 'NVME',
};
const ConfigIds = {
    AGENCIES: 'AGENCIES',
    DESTINATIONS: 'DESTINATIONS',
    INTEREST_RATE: 'INTEREST_RATE',
    PRICING_CMP: 'PRICING_CMP',
    PRICING_COM_BANCARIA: 'PRICING_COM_BANCARIA',
    PRICING_COM_BANCARIA_PRECIO_LISTA: 'PRICING_COM_BANCARIA_PRECIO_LISTA',
    PRICING_G_ADM: 'PRICING_G_ADM',
    PRICING_IIBB: 'PRICING_IIBB',
    PRICING_MARKUP: 'PRICING_MARKUP',
    SELLING_DISABLED: 'SELLING_DISABLED',
    AI_PROMPT_PRODUCT_META_DESCRIPTION: 'AI_PROMPT_PRODUCT_META_DESCRIPTION',
    BRANDS: 'BRANDS',
    PROVIDERS: 'PROVIDERS',
    FREE_SHIPPING: 'FREE_SHIPPING',
};

const ProductsWithReducedIVA = [
    ProductTypes.MONITOR,
];

const ConfigsThatAreNotTaxes = [
    ConfigIds.DESTINATIONS,
    ConfigIds.AGENCIES,
    ConfigIds.SELLING_DISABLED,
];

const PowerFormats = {
    ATX: 'ATX',
    SFX: 'SFX',
};

const PowerCertification80PlusOptions = {
    BRONZE: 'bronze',
    GOLD: 'gold',
    PLATINUM: 'platinum',
    TITANIUM: 'titanium',
};

const PowerCablingOptions = {
    FIJO: 'fijo',
    MODULAR: 'modular',
};

const LocationPowerSupply = {
    TOP: 'top',
    BOTTOM: 'bottom',
};

const NoLighting = 'noLighting';

module.exports = {
    CartProductTypes,
    ProductTypes,
    CpuBrands,
    RamStickTypes,
    CaseFanSlotWidths,
    StorageConnections,
    StorageProtocols,
    MotherFormFactors,
    FormFactors,
    CoolerTypes,
    ConfigIds,
    ProductsWithReducedIVA,
    ConfigsThatAreNotTaxes,
    PowerFormats,
    PowerCertification80PlusOptions,
    PowerCablingOptions,
    LocationPowerSupply,
    NoLighting,
};
