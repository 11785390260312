import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    SelectInput,
    required,
    BooleanInput,
    NumberInput,
} from 'react-admin';

import choices from '../choices';
import classes from './styles';

const CpuProductSpecsInput = () => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
            <SelectInput
                label="Modelo"
                source="specs.model"
                choices={choices.cpu.model}
                style={classes.textInputStyles}
            />
            <SelectInput
                label="Socket"
                source="specs.socket"
                choices={choices.cpu.socket}
                validate={[required()]}
                style={classes.textInputStyles}
            />
            <BooleanInput
                label="Tiene cooler"
                source="specs.has_cooler"
                defaultValue
            />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
            <NumberInput
                label="Watts consumidos"
                source="specs.watts"
                validate={[required()]}
                style={classes.textInputStyles}
            />
            <BooleanInput
                label="Gráficos integrados"
                source="specs.igpu"
            />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
            <NumberInput
                label="TDP medido en watts"
                source="specs.tdpInWatts"
                validate={[required()]}
                style={classes.textInputStyles}
            />
        </Box>
    </Box>
);

export default CpuProductSpecsInput;
