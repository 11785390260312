export const getProductIdsByType = ({ product, productFields }) => productFields
    .reduce((acc, { productType }) => {
        const productIds = product.specs?.[productType]?.products || [];
        if (productIds.length > 0) {
            acc[productType] = productIds;
        }
        return acc;
    }, {});

export const getAllProductIds = ({ product, productFields }) => Object.values(
    getProductIdsByType({ product, productFields }),
).flat();

export const formatSubtotal = (prev, price) => parseFloat((prev + Number(price)).toFixed(2));

export const calculateInitialPrices = (product, fields, productsById) => {
    const initialSelectedProducts = {};
    let initialSubTotalARS = 0;
    let initialSubTotalUSD = 0;

    Object.entries(getProductIdsByType({ product, productFields: fields }))
        .forEach(([productType, ids]) => {
            const selected = ids.map((id) => productsById[id]).filter(Boolean);
            initialSelectedProducts[productType] = selected;
            selected.forEach((item) => {
                initialSubTotalARS += Number(item.price?.special?.ARS || 0);
                initialSubTotalUSD += Number(item.pricing?.cost || 0);
            });
        });
    return {
        initialSelectedProducts,
        initialSubTotalARS: parseFloat(initialSubTotalARS.toFixed(2)),
        initialSubTotalUSD: parseFloat(initialSubTotalUSD.toFixed(2)),
    };
};
