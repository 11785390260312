const classes = {
    boxStyles: {
        gap: 16,
    },
    warningIcon: {
        color: 'orange',
        marginLeft: 4,
    },
};

export default classes;
