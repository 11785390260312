import * as React from 'react';
import { useState } from 'react';
import { Box } from '@material-ui/core';
import {
    AutocompleteArrayInput,
    SelectInput,
    BooleanInput,
    NumberInput,
    useInput,
} from 'react-admin';
import { required } from 'ra-core';
import {
    CoolerTypes,
} from '../../../helpers/types';

import choices from '../choices';
import classes from './styles';

const CpuCoolerProductSpecsInput = ({ record }) => {
    const caseRadiatorWidthInput = useInput({ source: 'specs.case_radiator_width' });
    const fanSizeInput = useInput({ source: 'specs.fanSize' });
    const [coolerType, setCoolerType] = useState(record?.specs?.cooler_type);

    const handleCoolerTypeChange = (event) => {
        const selectedCoolerType = event.target.value;
        setCoolerType(selectedCoolerType);

        if (selectedCoolerType !== CoolerTypes.WATER_COOLER) {
            caseRadiatorWidthInput.input.onChange(null);
        }

        if (selectedCoolerType !== CoolerTypes.AIR_COOLER_COOLER) {
            fanSizeInput.input.onChange(null);
        }
    };

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" style={classes.boxStyles}>
                <SelectInput
                    label="Tipo"
                    source="specs.cooler_type"
                    choices={choices.cpu_cooler.type}
                    onChange={handleCoolerTypeChange}
                    style={classes.textInputStyles}
                />
                {coolerType === CoolerTypes.WATER_COOLER && (
                    <SelectInput
                        label="Tamaño radiador"
                        source="specs.case_radiator_width"
                        choices={choices.cpu_cooler.case_radiator_width}
                        allowEmpty
                        style={classes.textInputStyles}
                        {...caseRadiatorWidthInput.input}
                    />
                )}
                {coolerType === CoolerTypes.AIR_COOLER && (
                    <SelectInput
                        label="Tamaño ventilador"
                        source="specs.fanSize"
                        allowEmpty
                        choices={choices.cpu_cooler.fan_sizes}
                        style={classes.textInputStyles}
                        {...fanSizeInput.input}
                    />
                )}
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
                <AutocompleteArrayInput
                    label="Socket"
                    addLabel
                    source="specs.sockets"
                    choices={choices.cpu.socket}
                    style={classes.textInputStyles}
                    validate={[required()]}
                />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
                <NumberInput
                    label="Watts consumed"
                    source="specs.watts"
                    validate={[required()]}
                    style={classes.textInputStyles}
                />
                <SelectInput
                    label="Iluminación"
                    source="specs.lighting"
                    allowEmpty
                    choices={choices.cpu_cooler.lighting_options}
                    style={classes.textInputStyles}
                />
                <BooleanInput
                    label="Pantalla"
                    source="specs.screen"
                    style={classes.textInputStyles}
                />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
                <NumberInput
                    label="TDP medido en watts"
                    source="specs.tdpInWatts"
                    validate={[required()]}
                    style={classes.textInputStyles}
                />
            </Box>
        </Box>
    );
};

export default CpuCoolerProductSpecsInput;
