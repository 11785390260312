import * as React from 'react';
import {
    DateField,
    FunctionField,
    NumberField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
} from 'react-admin';
import CouponTitle from '../CouponTitle';
import { couponsType } from '../constants';
import FieldsByType from './FieldsByType';

const CouponShow = (props) => (
    <Show title={<CouponTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="Main">
                <TextField source="code" label="Código" />
                <FunctionField source="type" label="Tipo" render={(rec) => couponsType?.find(({ id }) => id === rec?.type)?.name} />
                <FieldsByType {...props} />
                <DateField source="date_from" label="Desde" showTime />
                <DateField source="date_to" label="Hasta" showTime />
                <NumberField source="min_order_amount" label="Monto mínimo de la orden" />
                <NumberField source="max_uses" label="Límite de usos" />
                <NumberField source="current_uses" label="Usos realizados" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default CouponShow;
