import * as React from 'react';
import {
    Datagrid,
    EditButton,
    List,
    DateField,
    ShowButton,
    TextField,
    FunctionField,
} from 'react-admin';
import { couponsTypeKey, couponsTypeName } from './constants';
import { dateOptions } from '../../constants/dateOptions';

const CouponList = (props) => {
    const renderDiscountAmount = (record) => {
        if (record.type === couponsTypeKey.percentage) {
            return `${record.discount_percentage}%`;
        }
        return `$${record.discount_amount}`;
    };

    return (
        <List {...props} bulkActionButtons={false}>
            <Datagrid>
                <TextField label="Código" source="code" />
                <FunctionField label="TIpo" source="type" render={(record) => couponsTypeName[record.type]} />
                <FunctionField label="Descuento" render={renderDiscountAmount} />
                <DateField label="Desde" source="date_from" options={dateOptions} />
                <DateField label="Hasta" source="date_to" options={dateOptions} />
                <TextField label="Límite de usos" source="max_uses" />
                <TextField label="Usos actuales" source="current_uses" />
                <EditButton basePath="/coupons" />
                <ShowButton basePath="/coupons" />
            </Datagrid>
        </List>
    );
};

export default CouponList;
