import * as React from 'react';
import {
    Create,
    FormTab,
    TabbedForm,
} from 'react-admin';

import CouponCreateForm from './components/CouponCreateForm';

const CouponCreate = (props) => (
    <Create title="Create a Coupon" {...props}>
        <TabbedForm>
            <FormTab label="Main">
                <CouponCreateForm />
            </FormTab>
        </TabbedForm>
    </Create>
);

export default CouponCreate;
