import React, { useEffect, useState } from 'react';
import {
    SelectInput,
    BooleanInput,
    useDataProvider,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { required } from 'ra-core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import choices from '../../choices';
import GameCompatibilityInput from '../../Builds/GameCompatibilityInput';
import { productFields } from './constants';
import { isRecordNew } from '../utils';
import ProductBuildItem from './ProductBuildItem';
import { calculateInitialPrices, formatSubtotal, getAllProductIds } from './utils';

const BuildProductSpecsInput = ({ record }) => {
    const [subTotalARS, setSubTotalARS] = useState(0);
    const [subTotalUSD, setSubTotalUSD] = useState(0);
    const [selectedProducts, setSelectedProducts] = useState({});
    const recordIsNew = isRecordNew(record);

    const dataProvider = useDataProvider();

    const handleInitialPrices = () => {
        const allProductIds = getAllProductIds({ product: record, productFields });
        if (allProductIds.length > 0) {
            dataProvider
                .getList('products', {
                    filter: { id: allProductIds },
                    pagination: { page: 1, perPage: allProductIds.length },
                })
                .then(({ data }) => {
                    const productsById = data.reduce((acc, product) => {
                        acc[product.id] = product;
                        return acc;
                    }, {});

                    const {
                        initialSelectedProducts,
                        initialSubTotalARS,
                        initialSubTotalUSD,
                    } = calculateInitialPrices(record, productFields, productsById);

                    setSelectedProducts(initialSelectedProducts);
                    setSubTotalARS(initialSubTotalARS);
                    setSubTotalUSD(initialSubTotalUSD);
                })
                .catch((error) => {
                    console.error('Error fetching products:', error);
                });
        }
    };

    useEffect(() => {
        if (!recordIsNew) {
            handleInitialPrices();
        }
    }, []);

    const handleSelect = (id, price, pricing, productType) => {
        setSelectedProducts((prev) => ({
            ...prev,
            [productType]: [...(prev[productType] || []), { id, price, pricing }],
        }));
        setSubTotalARS((prev) => formatSubtotal(prev, price.special.ARS));
        setSubTotalUSD((prev) => formatSubtotal(prev, pricing.cost));
    };

    const handleSelectionChange = (newSelectedItems, productType) => {
        const previousSelectedProducts = selectedProducts[productType] || [];
        const removedProducts = previousSelectedProducts.filter(
            (item) => !newSelectedItems.includes(item.id),
        );

        if (removedProducts.length === 0) return;

        removedProducts.forEach((item) => {
            setSubTotalARS(
                (prev) => formatSubtotal(prev, -Number(item.price.special.ARS)),
            );
            setSubTotalUSD(
                (prev) => formatSubtotal(prev, -Number(item.pricing?.cost)),
            );
        });

        setSelectedProducts((prev) => ({
            ...prev,
            [productType]:
                previousSelectedProducts.filter((item) => newSelectedItems.includes(item.id)),
        }));
    };

    return (
        <>
            <Box display="flex" flexDirection="row" alignItems="center" gridGap={40} justifyContent="flex-end">
                <Typography variant="h6">Precio especial en ARS</Typography>
                <Typography variant="h6">{`$ ${subTotalARS}`}</Typography>
                <Typography variant="h6">Costo en USD</Typography>
                <Typography variant="h6">{`$ ${subTotalUSD}`}</Typography>
            </Box>
            <BooleanInput label="Customizable" source="specs.customizable" defaultValue />
            <SelectInput
                label="CPU Brand"
                source="specs.cpu_brand"
                choices={choices.cpu_brands}
                validate={[required()]}
            />
            <RichTextInput source="specs.operativeSystem" label="Sistema Operativo" />

            {productFields.map((product) => (
                <ProductBuildItem
                    key={product.productType}
                    product={product}
                    handleSelect={handleSelect}
                    handleSelectionChange={handleSelectionChange}
                />
            ))}

            <RichTextInput source="specs.connectivity" label="Conectividad" />
            <RichTextInput source="specs.ports" label="Puertos" />
            <GameCompatibilityInput />
        </>
    );
};

export default BuildProductSpecsInput;
