import * as React from 'react';
import {
    BooleanInput,
    Edit, FormDataConsumer, FormTab, NumberInput, TabbedForm, TextInput,
} from 'react-admin';
import { required } from 'ra-core';
import ConfigTitle from './ConfigTitle';
import { ConfigIds } from '../../helpers/types';
import Brands from './Brands';
import Providers from './Providers';

const showInput = (id) => {
    if (id === ConfigIds.SELLING_DISABLED) return (<BooleanInput source="value" label="" />);
    if (id === ConfigIds.INTEREST_RATE) return (<NumberInput source="value" label="Interés de la Cuota" validate={required()} />);
    if (id === ConfigIds.PRICING_CMP) return (<NumberInput source="value" label="Comisión Mercado Pago" validate={required()} />);
    if (id === ConfigIds.PRICING_COM_BANCARIA) return (<NumberInput source="value" label="Comisión bancaria" validate={required()} />);
    if (id === ConfigIds.PRICING_COM_BANCARIA_PRECIO_LISTA) return (<NumberInput source="value" label="Comisión bancaria (precio de lista)" validate={required()} />);
    if (id === ConfigIds.PRICING_G_ADM) return (<NumberInput source="value" label="Gastos Administrativos" validate={required()} />);
    if (id === ConfigIds.PRICING_IIBB) return (<NumberInput source="value" label="Ingresos Brutos" validate={required()} />);
    if (id === ConfigIds.PRICING_MARKUP) return (<NumberInput source="value" label="Markup" validate={required()} />);
    if (id === ConfigIds.AI_PROMPT_PRODUCT_META_DESCRIPTION) return (<TextInput multiline source="value" label="Meta description prompt" validate={required()} style={{ width: '500px' }} />);
    if (id === ConfigIds.BRANDS) return <Brands />;
    if (id === ConfigIds.PROVIDERS) return <Providers />;
    if (id === ConfigIds.FREE_SHIPPING) return (<BooleanInput source="value" label="" />);
    return null;
};

export default (props) => (
    <Edit title={<ConfigTitle />} {...props}>
        <TabbedForm>
            <FormTab label="Main">
                <TextInput source="id" validate={[required()]} disabled />
                <FormDataConsumer>
                    {(
                        ({ formData, ...rest }) => showInput(formData.id)
                    )}
                </FormDataConsumer>
            </FormTab>
        </TabbedForm>
    </Edit>
);
