import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    Labeled,
    NumberField,
    SelectField,
    TextField,
    BooleanField,
    RichTextField,
    ReferenceArrayField,
    Datagrid,
    ShowButton,
    ReferenceField,
    ArrayField,
} from 'react-admin';
import choices from '../choices';
import MonitorProductSpecsField from './MonitorProductSpecsField';
import MouseProductSpecsField from './MouseProductSpecsField';
import KeyboardProductSpecsField from './KeyboardProductSpecsField';
import RAMProductSpecsField from './RAMProductSpecsField';
import CpuProductSpecsField from './CpuProductSpecsField';
import HeadsetProductSpecsField from './HeadsetProductSpecsField';
import ChairProductSpecsField from './ChairProductSpecsField';
import GpuProductSpecsField from './GpuProductSpecsField';
import CaseFanProductSpecsField from './CaseFanProductSpecsField';
import CpuCoolerProductSpecsField from './CpuCoolerProductSpecsField';
import MotherProductSpecsField from './MotherProductSpecsField';
import StorageProductSpecsField from './StorageProductSpecsField';
import PowerProductSpecsField from './PowerProductSpecsField';
import CaseProductSpecsField from './CaseProductSpecsField';
import BuildProductSpecsField from './BuildProductSpecsField';

export default ({ record, ...rest }) => {
    const specsForTypes = {
        case: <CaseProductSpecsField record={record} />,
        cpu_cooler: <CpuCoolerProductSpecsField record={record} />,
        power: <PowerProductSpecsField record={record} />,
        gpu: <GpuProductSpecsField record={record} />,
        storage: <StorageProductSpecsField record={record} />,
        cpu: <CpuProductSpecsField record={record} />,
        ram: <RAMProductSpecsField record={record} />,
        mother: <MotherProductSpecsField record={record} />,
        mouse: <MouseProductSpecsField record={record} />,
        monitor: <MonitorProductSpecsField record={record} />,
        build: <BuildProductSpecsField record={record} {...rest} />,
        case_fan: (<CaseFanProductSpecsField record={record} />),
        keyboard: (
            <KeyboardProductSpecsField record={record} />
        ),
        headset: (
            <HeadsetProductSpecsField record={record} />
        ),
        chair: (
            <ChairProductSpecsField record={record} />
        ),
    };
    return specsForTypes[record.type] ?? <></>;
};
