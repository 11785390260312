// eslint-disable-next-line import/prefer-default-export

export const couponsTypeKey = {
    percentage: 'percentage',
    fixed: 'fixed',
};

export const couponsTypeName = {
    percentage: 'Porcentaje',
    fixed: 'Monto Fijo',
};

export const couponsTypeSimbol = {
    percentage: '%',
    fixed: '$',
};

export const couponsType = [
    { id: couponsTypeKey.percentage, name: couponsTypeName.percentage },
    { id: couponsTypeKey.fixed, name: couponsTypeName.fixed },
];
