import React from 'react';
import { NumberField } from 'react-admin';
import WarningIcon from '@material-ui/icons/Warning';
import { Box } from '@material-ui/core';
import { ProductTypes } from '../../helpers/types';

const StockField = ({ record = {} }) => (
    <Box display="flex" alignItems="center">
        <NumberField source="stock" record={record} />
        {record.stock <= 0 && record.type === ProductTypes.BUILD && (
            <WarningIcon style={{ color: 'orange', marginLeft: 4 }} />
        )}
    </Box>
);

export default StockField;
