import * as React from 'react';
import { NumberField, Labeled } from 'react-admin';
import { Box } from '@material-ui/core';

import { couponsTypeKey } from '../constants';

const FieldsByType = ({ record }) => {
    const isPercentage = record.type === couponsTypeKey.percentage;
    const isFixed = record.type === couponsTypeKey.fixed;

    if (isPercentage) {
        return (
            <Box display="flex" flexDirection="column">
                <Labeled label="Porcentaje de descuento">
                    <NumberField source="discount_percentage" label="Porcentaje de descuento" record={record} />
                </Labeled>
                <Labeled label="Tope de reintegro">
                    <NumberField source="refund_limit" label="Tope de reintegro" record={record} />
                </Labeled>
            </Box>
        );
    }
    if (isFixed) {
        return (
            <Labeled label="Monto de descuento">
                <NumberField source="discount_amount" label="Monto de descuento" record={record} />
            </Labeled>
        );
    }
    return null;
};

export default FieldsByType;
