import * as React from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
} from 'react-admin';
import CouponTitle from './CouponTitle';
import CouponCreateForm from './components/CouponCreateForm';

const CouponEdit = (props) => (
    <Edit title={<CouponTitle />} {...props}>
        <TabbedForm>
            <FormTab label="Main">
                <CouponCreateForm />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default CouponEdit;
