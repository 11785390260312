import * as React from 'react';
import { Box, Tooltip } from '@material-ui/core';
import {
    TextInput,
    SelectInput,
    NumberInput,
} from 'react-admin';
import { required } from 'ra-core';

import choices from '../choices';
import classes from './styles';

const ramWattsTooltipMessage = () => 'La cantidad de watts debe ser el producto entre cantidad y los watts que consume una RAM unitaria.';

const RAMProductSpecsInput = () => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <SelectInput
                label="Tipo"
                source="specs.type"
                choices={choices.ram.type}
                validate={[required()]}
                defaultValue="dimm"
                style={classes.textInputStyles}
            />
            <SelectInput
                label="Capacidad"
                source="specs.capacity"
                choices={choices.ram.capacity}
                validate={[required()]}
                defaultValue="8gb"
                style={classes.textInputStyles}
            />
            <SelectInput
                label="Tecnología"
                source="specs.stick_type"
                choices={choices.ram.stick_type}
                validate={[required()]}
                defaultValue="ddr4"
                style={classes.textInputStyles}
            />
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <NumberInput
                label="Stick Quantity"
                source="specs.quantity"
                initialValue="1"
                validate={[required()]}
                style={classes.textInputStyles}
            />
            <TextInput
                label="Frecuencia"
                source="specs.clock_frequency"
                validate={[required()]}
                style={classes.textInputStyles}
            />
            <SelectInput
                label="Unidad"
                source="specs.clockFrequencyUnit"
                choices={choices.ram.clock_frequency_unit}
                validate={[required()]}
                style={classes.textInputStyles}
            />
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <TextInput
                label="Latencia"
                source="specs.cl"
                validate={[required()]}
                style={classes.textInputStyles}
            />
            <Tooltip title={ramWattsTooltipMessage()}>
                <NumberInput
                    label="Watts consumed"
                    source="specs.watts"
                    validate={[required()]}
                    style={classes.textInputStyles}
                />
            </Tooltip>
        </Box>
    </Box>
);

export default RAMProductSpecsInput;
