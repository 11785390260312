/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';
import { ArrayField, useRecordContext, useGetMany } from 'react-admin';

import {
    Box, Table, TableRow, TableBody, TableCell,
} from '@material-ui/core';
import cartHelper from '../../../helpers/cartHelper';

import OrderProductsTableHead from './OrderProductsTableHead';
import OrderProductTableRow from './OrderProductTableRow';
import OrderShippingDataTable from './OrderShippingDataTable';
import { couponsType, couponsTypeKey } from '../../Coupons/constants';

const getInstallments = (installmentQuantity, installmentAmount) => `${installmentQuantity} 
    cuotas (${cartHelper.formatPrice(installmentAmount)})`;

const getSubtotal = (orderProducts, priceType) => {
    const items = orderProducts.map((orderProduct) => orderProduct.item);
    return items.reduce((acc, item) => {
        const subtotal = (item.price[priceType] ?? 0) * (item.quantity ?? 0);
        return acc + subtotal;
    }, 0);
};

const OrderProductsTable = ({ source, editable }) => {
    const { record: order } = useRecordContext();
    const orderProducts = order?.[source];
    if (!orderProducts || orderProducts.length === 0) {
        return null;
    }
    const {
        data: fetchedProducts, loading, error,
    } = useGetMany(
        'products',
        orderProducts.map((orderProduct) => orderProduct.item.product),
    );

    const buildsDictionary = useMemo(() => {
        const buildsWithoutNullsAndDuplicated = [...new Set(orderProducts
            .map((orderProduct) => orderProduct.item.buildId)
            .filter((id) => Boolean(id)))]
            .sort((a, b) => a.localeCompare(b));
        return Array.from(buildsWithoutNullsAndDuplicated)
            .reduce((acc, id, index) => {
                acc[id] = index + 1;
                return acc;
            }, {});
    });

    return (
        <Box>
            <Table>
                <OrderProductsTableHead />
                <TableBody>
                    {fetchedProducts && !loading && !error && orderProducts.map((orderProduct) => (
                        <OrderProductTableRow
                            key={orderProduct.item.productShadow.code}
                            orderProduct={orderProduct}
                            fetchedProduct={
                                fetchedProducts.find(
                                    (product) => product?.id === orderProduct.item.product,
                                ) || undefined
                            }
                            editable={editable}
                            orderId={order.id}
                            priceType={order.priceType}
                            buildId={buildsDictionary[orderProduct.item.buildId] || '-'}
                        />
                    ))}
                    <TableRow>
                        <TableCell colSpan={10} align="right">
                            <strong>Subtotal:</strong>
                        </TableCell>
                        <TableCell>
                            {cartHelper.formatPrice(
                                getSubtotal(orderProducts, order.priceType),
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={8} align="right">
                            <strong>Cupón:</strong>
                        </TableCell>
                        <TableCell>{order.appliedCoupon?.code ?? ' - '}</TableCell>
                        {
                            !order.appliedCoupon && <TableCell> - </TableCell>
                        }
                        {
                            order.appliedCoupon && (
                                <TableCell>
                                    {order.appliedCoupon?.type === couponsTypeKey.percentage ? `${order.appliedCoupon?.discount_percentage} %` : `${cartHelper.formatPrice(order.appliedCoupon.discount_amount)}`}
                                </TableCell>
                            )
                        }
                        <TableCell style={{ color: 'red' }}>
                            -
                            {' '}
                            {cartHelper.formatPrice(order.productsPriceDiscount)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3} />
                        <TableCell colSpan={8}>
                            <OrderShippingDataTable order={order} editable={editable} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={6} align="right">
                            <strong>Cuotas / Valor: </strong>
                        </TableCell>
                        <TableCell colSpan={2}>
                            {getInstallments(
                                order.paymentData.mpResponse?.response?.installments ?? 1,
                                order.paymentData.mpResponse?.response?.transaction_details
                                    ?.installment_amount ?? order.totalPrice,
                            )}
                        </TableCell>
                        <TableCell colSpan={2} align="right">
                            <strong>Total: </strong>
                        </TableCell>
                        <TableCell>{cartHelper.formatPrice(order.totalPrice)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
};

const DetailedProducts = ({ editable, ...all }) => (
    <ArrayField source="products" {...all}>
        <OrderProductsTable source="products" editable={editable} />
    </ArrayField>
);

export default DetailedProducts;
