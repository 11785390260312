import * as React from 'react';
import {
    BooleanField, NumberField, DateField,
    Datagrid,
    List,
    TextField,
} from 'react-admin';
import { dateOptions } from '../../constants/dateOptions';

export default (props) => (
    <List {...props} sort={{ field: 'datetime', order: 'DESC' }} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="currency" />
            <NumberField source="exchangeRate" />
            <DateField source="datetime" options={dateOptions} />
            <BooleanField source="actual" />
        </Datagrid>
    </List>
);
