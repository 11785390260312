import * as React from 'react';
import {
    TextInput,
    NumberInput,
    DateTimeInput,
    RadioButtonGroupInput,
} from 'react-admin';
import { Grid, Box, Divider } from '@material-ui/core';
import { required } from 'ra-core';
import { useFormState } from 'react-final-form';

import { couponsTypeKey, couponsType } from '../constants';

const CouponCreateForm = () => {
    const { values: { type } } = useFormState();

    const isPercentage = type === couponsTypeKey.percentage;
    const isFixed = type === couponsTypeKey.fixed;

    return (
        <>
            <Grid container>
                <Grid item md={5}>
                    <Box>
                        <TextInput source="code" label="Código" validate={[required()]} />
                    </Box>
                    <Box>
                        <RadioButtonGroupInput source="type" label="Tipo" defaultValue={couponsTypeKey.percentage} choices={couponsType} validate={[required()]} />
                    </Box>
                    {
                        isPercentage && (
                            <>
                                <Box>
                                    <NumberInput source="discount_percentage" label="Porcentaje de descuento" min={0} max={100} validate={[required()]} />
                                </Box>
                                <Box>
                                    <NumberInput source="refund_limit" label="Tope de reintegro" />
                                </Box>
                            </>
                        )
                    }
                    {
                        isFixed && (
                            <Box>
                                <NumberInput source="discount_amount" label="Monto de descuento" min={0} validate={[required()]} />
                            </Box>
                        )
                    }
                </Grid>
            </Grid>
            <Divider />
            <Grid container>
                <Grid item md={5}>
                    <Box display="flex" style={{ gap: 20 }}>
                        <DateTimeInput source="date_from" label="Desde" showTime validate={[required()]} />
                        <DateTimeInput source="date_to" label="Hasta" showTime />
                    </Box>
                    <Box>
                        <NumberInput source="max_uses" label="Límite de usos" min={0} />
                    </Box>
                    <Box>
                        <NumberInput source="min_order_amount" label="Monto mínimo de la orden" min={0} />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default CouponCreateForm;
